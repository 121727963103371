import styles from './EditUser.module.scss';
import Container from 'components/Container/Container';
import Client from 'api/client';
import useStore from 'store';
import React from 'react';
import {
  unstable_FormSubmitButton as FormSubmitButton,
  unstable_useFormState as useFormState,
  unstable_FormMessage as FormMessage,
} from 'reakit/Form';
import { navigate, useLocation } from '@reach/router';
import InputField from '@moonshineragency/ui/src/components/InputField/InputField';
import Button from '@moonshineragency/ui/src/components/Button/Button';
import Heading from '@moonshineragency/ui/src/components/Heading/Heading';
import Form, {
  renderInputErrorFor,
} from '@moonshineragency/ui/src/components/Form/Form';

const ResetPassword = () => {
  const store = useStore();
  const passwordValidateValue = /^.{8,}$/;
  const passwordValidate = value => {
    return passwordValidateValue.test(value);
  };

  const location = useLocation();
  const userToken = location.search.slice(7);

  const form = useFormState({
    values: {
      password: '',
      password_confirmation: '',
    },
    onValidate: values => {
      let errors = {};
      if (!values.password) {
        errors = {
          ...errors,
          password: renderInputErrorFor('Please enter new password'),
        };
      }
      if (
        !values.password_confirmation ||
        values.password_confirmation !== values.password
      ) {
        errors = {
          ...errors,
          password_confirmation: renderInputErrorFor(
            'Please enter same password ',
          ),
        };
      }
      if (!passwordValidate(values.password) && values.password) {
        errors = {
          ...errors,
          password: renderInputErrorFor('Please enter at least 8 characters '),
        };
      }
      if (Object.keys(errors).length) {
        throw errors;
      }
    },
    onSubmit: async values => {
      try {
        await Client.resetPassword.post({ ...values, token: userToken });
        store.setUser(null);
      } catch (e) {
        form.errors = { login: '' };
        throw form.errors;
      }
      navigate('/admin/trials/list');
    },
  });
  return (
    <Container size="sm">
      <Form form={form}>
        <div className={styles.resetWrapper}>
          <Heading size="h2" theme="primary">
            Reset password
          </Heading>
          <div className={styles.resetInputField}>
            <InputField
              type="password"
              name="password"
              label="New password"
              {...form}
              noMessage
            />
            <FormMessage
              {...form}
              name="password"
              className={styles.errorMessage}
            />
          </div>
          <div className={styles.resetInputField}>
            <InputField
              type="password"
              name="password_confirmation"
              label="New password again"
              {...form}
              noMessage
            />
            <FormMessage
              {...form}
              name="password_confirmation"
              className={styles.errorMessage}
            />
          </div>
          <div className={styles.resetActionWrapper}>
            <div className={styles.resetAction}>
              <FormSubmitButton
                as={Button}
                theme="primary"
                {...form}
                className={styles.saveButton}
              >
                Reset password
              </FormSubmitButton>
            </div>
          </div>
        </div>
      </Form>
    </Container>
  );
};

export default ResetPassword;
